import React , { Component } from 'react';
import {MDBCol,MDBRow} from 'mdbreact'
import {S3_URL} from '../constants/constants'
import { Link } from 'react-router-dom'
// import HomeContent from "../assets/img/HomePageAssets/Qapter_presentation-3.jpg";
// import HomeContent2 from "../assets/img/HomePageAssets/Qapter_presentation-8.jpg";

class Content extends Component{
    constructor(props){
        super(props)
        this.state = {
            content:[]
        }
    }
    componentDidMount() {
      fetch("https://audatexcms.herokuapp.com/api/v1/get-content",
      {
        method: 'GET',
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              content: result
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log('error')
          }
        )
    }
    render(){
      const contentArray= this.state.content
      console.log(contentArray)
      if(contentArray.length>0){
       return(
       <div>
        <MDBRow>
        <MDBCol>
         <Link to={`/content/`+contentArray[0].id}> <img className="img-fluid" src={S3_URL+contentArray[0].img} alt="" /></Link>
        </MDBCol>
   
        <MDBCol lg="7">
        <h1 style={{ color: 'white'}}><span>{contentArray[0].title}</span></h1>
        <br/>
       <p style={{color:"white"}}>{contentArray[0].description}</p>
       <br/>
       <p style={{textAlign:'right',color:'white'}}>{contentArray[0].created_at}</p>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol lg="7"></MDBCol>
        <MDBCol>
         {/*<img className="img-fluid" src={HomeContent2} alt="" />*/}
        </MDBCol>
      </MDBRow>
      </div>
       )
    }
    else{
      return(<div></div>)
    }
  }

}
export default Content