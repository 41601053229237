import { MDBCol, MDBContainer } from "mdbreact";
import React, { useEffect } from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement("script");
    script.src =
      "https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.id = "otprivacy-notice-script";
    script.setAttribute(
      "settings",
      "eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vZ2xvYmFsZHNhci5zb2xlcmEuY29tL3JlcXVlc3QvdjEvcHJpdmFjeU5vdGljZXMvc3RhdHMvdmlld3MiLCJjb250ZW50QXBpVXJsIjoiaHR0cHM6Ly9nbG9iYWxkc2FyLnNvbGVyYS5jb20vcmVxdWVzdC92MS9lbnRlcnByaXNlcG9saWN5L2RpZ2l0YWxwb2xpY3kvY29udGVudCIsIm1ldGFkYXRhQXBpVXJsIjoiaHR0cHM6Ly9nbG9iYWxkc2FyLnNvbGVyYS5jb20vcmVxdWVzdC92MS9lbnRlcnByaXNlcG9saWN5L2RpZ2l0YWxwb2xpY3kvbWV0YS1kYXRhIn0=",
    );

    // Append script to the body
    document.body.appendChild(script);

    // Initialize OneTrust NoticeApi
    script.onload = () => {
      if (window.OneTrust) {
        window.OneTrust.NoticeApi.Initialized.then(() => {
          window.OneTrust.NoticeApi.LoadNotices([
            "https://privacyportal-cdn.onetrust.com/storage-container/20bbc57b-6134-4535-8062-a1745b771851/privacy-notices/dfc25593-e3db-47b5-9529-562f8a15e3ef/published/privacynotice.json",
          ]);
        });
      }
    };

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="divBackground">
      <NavBar />
      <div className="spacer" />
      <MDBContainer className="divBackground">
        <div className="spacer" />

        <br />

        <MDBCol>
          <h1 className="h1-style">Privacy Policy</h1>
          <MDBContainer>
            {/* Language Drop-down element that will control in which language the Privacy Notice is displayed */}
            <div className="ot-privacy-notice-language-dropdown-container"></div>
            {/* Container in which the Privacy Notice will be rendered */}
            <div
              id="otnotice-dfc25593-e3db-47b5-9529-562f8a15e3ef"
              className="otnotice"
              style={{ color: "black" }}
            ></div>
          </MDBContainer>
        </MDBCol>
      </MDBContainer>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
