import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.css'
import AboutUsPage from './components/AboutUsPage'
import HomePage from './components/HomePage'
import PrivacyPolicyPage from './components/PrivacyPolicyPage'
import SingleContentPage from './components/SingleContentPage'
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route
          exact
          path="/content/:contentId"
          render={(props) => {
            return <SingleContentPage {...props} />
          }}
        />
      </Switch>
    </Router>
  )
}

export default App
