import React from "react";
import NavBar from "./NavBar";
import "../assets/css/index.css";
import Footer from "./Footer";
import { MDBCol, MDBContainer } from "mdbreact";
import SoleraLogo from "../assets/img/HomePageAssets/Solera Logo.jpg";
import Qapter_Presentation from "../assets/img/HomePageAssets/Qapter_presentation-1.jpg";
class AboutUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }
  render() {
    return (
      <div className="divBackground">
        <NavBar />
        <div className="spacer" />
        <MDBContainer className="divBackground">
          <div className="spacer" />

          <br />
          <MDBCol>
            <h1 className="h1-style">About</h1>
            <img className="mh-25 center-image" src={SoleraLogo} alt="" />
            <MDBContainer>
              <p className="h3-style">Then</p>

              <p className="pStyleContent">
                Audatex was founded in 1966 by Swiss Re Corporation as a service
                company providing solutions from first notification to final
                settlement. Audatex soon became the pioneer in simplified damage
                calculation and expanded quickly to over 67 countries and more
                than 75,000 corporate clients. Audatex was aquired by it’s
                current parent company, Solera Holdings Inc., in 2006. Audatex
                Singapore was established in 2010 in order to promote a more
                transparent and efficient motor repair claims process. By
                connecting and building strong strategic partnerships with
                prominent insurers & authorised repairers locally, Audatex
                Singapore established itself as a trusted platform for repair
                estimation.
              </p>
            </MDBContainer>
            <MDBContainer>
              <p className="h3-style">Now</p>

              <p className="pStyleContent">
                Audatex Singapore today has strong partnerships with key
                insurers as well as their partner authroised repairers and
                independent assessor firms. Equipped with the latest web based
                estimation platform, Qapter Claims, repairers, assessors &
                claims controllers are all able to create, view & amend
                estimates with a level of ease and accessibility like never
                before. Claims estimation has been transformed to a
                standardised, transparent & intuitive process.
                <br />
                By listening to our customers as well as our partners, we are
                constantly evolving and improving our services & practices in
                order to better facilitate an easier claims estimations. By
                bringing every party involved in a claims estimation process
                together, Audatex Singapore aims to bring better understanding
                of what really goes on when a car is repaired.
              </p>
            </MDBContainer>
          </MDBCol>
          <hr />
          <div className="spacer" />
          <img
            className="mh-25 center-image"
            src={Qapter_Presentation}
            alt=""
          />
        </MDBContainer>
        <Footer />
      </div>
    );
  }
}

export default AboutUsPage;
