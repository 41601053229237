import React,{ Component } from "react"
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBIcon
} from 'mdbreact'
import GoogleMap from './GoogleMaps'



class ContactUs extends Component{


    render(){
        return(
            <div>
            <MDBContainer>
            <div className="spacer" />
            <GoogleMap />
            <div className="spacer" />
            <MDBContainer>
              <h3 className="h3Style">
                AUDATEX SINGAPORE PTE LTD
                <br />
                7 TEMASEK BOULEVARD
                <br />
                SUNTEC TOWER ONE, LEVEL 32
                <br />
                SINGAPORE 038987
              </h3>
              <hr />
            </MDBContainer>{" "}
            <MDBCol>
              <h1 className="h1-style">Contact Us</h1>
            </MDBCol>
            <MDBRow>
              <MDBCol md="6">
                <p className="pStyleContent">Phone Inquiry</p>
                <p className="pStyleContent">
                  Sales Support - Tomy: +65 9456 7628
                </p>

                <br />
                <p className="pStyleContent">Email Inquiry:</p>
                <p className="pStyleContent">
                  <a href="mailto: support.sg@audatex.com">
                    support.sg@audatex.com
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="6">
                <form>
                  <p className="pStyleContent">
                    FOR ALL INQURIES, PLEASE FILL IN THIS FORM FOR US TO HELP YOU:
                  </p>
                  <label
                    htmlFor="defaultFormContactEmailEx"
                    className="grey-text"
                  >
                    Email*
                  </label>
                  <input
                    type="email"
                    id="defaultFormContactEmailEx"
                    className="form-control"
                  />
                  <br />
                  <label
                    htmlFor="defaultFormContactSubjectEx"
                    className="grey-text"
                  >
                    Subject*
                  </label>
                  <input
                    type="text"
                    id="defaultFormContactSubjectEx"
                    className="form-control"
                  />
                  <label
                    htmlFor="defaultFormContactSubjectEx"
                    className="grey-text"
                  >
                    Contact Number*
                  </label>
                  <input
                    type="text"
                    id="defaultFormContactSubjectEx"
                    className="form-control"
                  />
                  <br />
                  <label
                    htmlFor="defaultFormContactMessageEx"
                    className="grey-text"
                  >
                    Type of Inquiry*
                  </label>
                  <br />
                  <select>
                    <option value="Repairer">Sales/Business Enquiries</option>
                    <option value="Insurer">Technical Support</option>
                    <option value="Surveryor">Miscellaneous</option>
                  </select>
                  <br />
                  <label
                    htmlFor="defaultFormContactMessageEx"
                    className="grey-text"
                  >
                    Messsage*
                  </label>
                  <textarea
                    type="text"
                    id="defaultFormContactMessageEx"
                    className="form-control"
                    rows="3"
                  />

                  <div className="text-center mt-4">
                    <MDBBtn color="warnin" outline type="submit" className="butn">
                      Submit
                      <MDBIcon far icon="paper-plane" className="ml-2" />
                    </MDBBtn>
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          </div>
        )
    }

}

export default ContactUs