import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

class Footer extends Component {
  render() {
    return (
      <MDBFooter color="blac" className="font-small pt-4 mt-4" style={{color: "white"}}>
        <MDBContainer className="text-center text-md-left">
          
          <MDBRow className="d-flex align-items-center">
            <MDBCol md="6" lg="6">
              <p className="text-center text-md-left grey-text">
                &copy; {new Date().getFullYear()} Copyright:{" "}
                <a href="/"> Audatex Singapore </a>
              </p>
            </MDBCol>
            <MDBCol md="6" lg="6" className="ml-lg-0">
              <p className="text-center text-md-right">
			  <a href="/privacy-policy"> Privacy Policy </a> | <a href="https://globaldsar.solera.com/webform/20bbc57b-6134-4535-8062-a1745b771851/24fa2f7a-2dc7-4d97-8165-17c6ee06f389" target="_blank"  rel="noopener noreferrer">Exercise Your Rights</a>
			  
			  

			  </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBFooter>
    );
  }
}
export default Footer;
