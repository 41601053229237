import React from "react";
import {
  MDBCarousel,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBView,
  MDBCol,
  MDBContainer,
  MDBCarouselInner,
  MDBCarouselItem
} from "mdbreact";

import SoleraLogo from "../assets/img/HomePageAssets/Solera Logo.jpg";
import Qapter_Presentation from "../assets/img/HomePageAssets/Qapter_presentation-1.jpg";
import Qapter_Presentation1 from "../assets/img/HomePageAssets/Qapter_presentation-12.jpg";
import NavBar from "./NavBar";
import "../assets/css/index.css";
import Slide2 from "../assets/img/HomePageAssets/Slide 2.jpg";
import ContactUs from './ContactUs';
import Footer from "./Footer";
import Content from './Content'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  render() {
    return (
      <div>
        <NavBar />

        <div className="coverStyle">
          <div className="spacer" />
          <MDBMask
            overlay="black-light"
            className="flex-center flex-column text-white text-center"
          >
            <div className="spacer" />
			<br />
			<br />
			<br />
			<br />
            <br />
			<br />
			<br />
            <br />
			<br />
			<h1 className="h1Style">WHAT IS AUDATEX?</h1>
            <p className="pStyle">
              Audatex is a global leader in automotive repair estimation.{" "}
            </p>
			<br />
            <p className="pStyle">
              Using Audatex’s newest estimating platform, what repairers do is
              better understood by assessors & car owners alike.
            </p>
            <p className="pStyle">
              {" "}
              Audatex provides OEM times direct from the repair manual, allowing
              for more official guidelines on both mechanical labour as well as
              paintwork.
            </p>

            <hr />
            <div className="spacer" />

            <div>
              <MDBCarousel
                activeItem={1}
                length={3}
                showControls={true}
                showIndicators={true}
                className="z-depth-1"
                interval={3000}
              >
                <MDBCarouselInner>
                  <MDBCarouselItem itemId="1">
                    <MDBView>
                      <img
                        className="d-block w-100"
                        src={SoleraLogo}
                        alt="First slide"
                      />
                    </MDBView>
                  </MDBCarouselItem>
                  <MDBCarouselItem itemId="2">
                    <MDBView>
                      <img
                        className="d-block w-100"
                        src={Qapter_Presentation}
                        alt="Second slide"
                      />
                    </MDBView>
                  </MDBCarouselItem>
                  <MDBCarouselItem itemId="3">
                    <MDBView>
                      <img
                        className="d-block w-100"
                        src={Slide2}
                        alt="Third slide"
                      />
                    </MDBView>
                  </MDBCarouselItem>
                </MDBCarouselInner>
              </MDBCarousel>
            </div>
            <div className="spacer" />
          </MDBMask>
        </div>

        <MDBCard className="divBackground">
          <MDBCardBody>
            <div className="spacer" />
			<img
              className="mw-50 center-image"
              src={Qapter_Presentation1}
              alt=""
            />
            <br />
            <MDBCol>
              <MDBContainer>
                <h3 className="h3Style">Brief Intro</h3>
                <br />

                <p className="pStyleContent">
                  We have been delivering globally proven data and technology 
				  solutions for the claims industry in Singapore since 2010. 
				  Audatex Singapore is a part of Solera, a global leaders in 
				  technology. By using Solera’s best-in-class technology 
				  solutions help customers automate their claims, reduce costs 
				  and increase operational efficiency.
                </p>
              </MDBContainer>
            </MDBCol>
       
            <Content />
            <div className="spacer" />
            <hr />
          </MDBCardBody>
        </MDBCard>
        <div className="divBackground">
          <ContactUs/>

          <div className="spacer" />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Header;
