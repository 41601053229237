import React from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBBtn
} from "mdbreact";
import logo from "../assets/img/logo.png";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  render() {
    return (
      <div>
        <header>
          <MDBNavbar color="blac" fixed="top" dark expand="md">
            <MDBNavbarBrand href="/">
              <img src={logo} alt="" width="290" height="33" />
            </MDBNavbarBrand>
            {!this.state.isWideEnough && (
              <MDBNavbarToggler onClick={this.onClick} />
            )}
            <MDBCollapse isOpen={this.state.collapse} navbar>
              <MDBNavbarNav right>
                <MDBNavItem>
                  <MDBNavLink to="/">Home</MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="/about-us">About</MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBBtn
                    size="sm"
                    tag="a"
                    role="button"
                    href="https://www.audatex.sg/sso/login?service=https%3A%2F%2Fwww.audatex.sg%2Fbre"
                    outline
                    color="deep-orang"
                  >
                    Login
                  </MDBBtn>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
        </header>
      </div>
    );
  }
}

export default NavBar;
